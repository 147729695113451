const QUERYPARAMS = {
    hostVersion: "hostversion",
    deepSupport: "deepsupport",
};

// bitwise operations are done in 32bits signed integers. So, the maximum value is 1 << 30
const DEEPLINKSUPPORT = {
    findMy: 1 << 0,
    realtimeBoost: 1 << 1,
}

function getAppOs(navigator, window){
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    } else {
      return 'iOS';
    }
}

export {
    QUERYPARAMS,
    DEEPLINKSUPPORT,
    getAppOs
};