const LS_FULLVERSION = 'AppCompatFullVersion';
const LS_VERSION = 'AppCompatVersion';
// platform is derived from version.
const LS_PLATFORM = 'AppCompatPlatform';
const LS_BUNDLE_ID = 'AppCompatBundleId';
const LS_DEEPLINK_CAPABILITY = 'AppCompatDeeplinkCapability';
const LS_HOST_OS = 'AppCompatHostOS'

import {DEEPLINKSUPPORT} from '@/api/appcompat.api'

const state = {
    isVerifyNative: JSON.parse(localStorage.getItem("verifyNative")),
    fullVersion: localStorage.getItem(LS_FULLVERSION),
    platform: localStorage.getItem(LS_PLATFORM),
    bundleId: localStorage.getItem(LS_BUNDLE_ID),
    intVersion: localStorage.getItem(LS_VERSION),
    deeplinkCap: localStorage.getItem(LS_DEEPLINK_CAPABILITY),
    hostOS: localStorage.getItem(LS_HOST_OS),

}
const getters = {
    isVerifyNative: state => {
        return state.isVerifyNative;
    },

    isFindMy: state => {
        
        const bitMask = DEEPLINKSUPPORT.findMy
        return (state.deeplinkCap & bitMask) == bitMask
        
        // console.log(state.platform)
        // console.log(state.bundleId)
        // console.log(state.intVersion)
        
        // if (state.platform == "ios") {
        //     if (state.fullVersion.includes("debug")) {
        //         return true
        //     } 
        //     if (state.bundleId == 'track') {
        //         return state.intVersion >= 60904
        //     }
        // }
        // else if (state.plaform == 'android') {
        //     return false
        // }
        // return false
    },

    isRealtimeBoost: state => {
        
        const bitMask = DEEPLINKSUPPORT.realtimeBoost
        return (state.deeplinkCap & bitMask) == bitMask
        // console.log("isRealtimeBoost")
        // console.log(bitMask)
        // console.log(state.deeplinkCap)
        // console.log(state.deeplinkCap & bitMask)
        // if ((state.deeplinkCap & bitMask) == bitMask) {
        //     console.log("YES RTB")
        //     return true
        // }
        // console.log("NO RTB")
        // return false
    },

    hostOS: state => {
        return state.hostOS
    }
    
}

const actions = {
    setIsVerifyNative({ commit }, data) {
        localStorage.setItem("verifyNative", data);
        commit('SET_VERIFY_NATIVE', data);
    },
    setHostAppVersion({ commit }, data) {
        
        localStorage.setItem(LS_FULLVERSION, data);
        commit('SET_FULL_VERSION', data);

        let array = data.split("-")
        if (array.length > 0) {
            let platform = array[0]
            localStorage.setItem(LS_PLATFORM, platform)
            commit('SET_PLATFORM', platform)
        }
        if (array.length > 1) {
            let bundleId = array[1]
            localStorage.setItem(LS_BUNDLE_ID, bundleId)
            commit('SET_BUNDLEID', bundleId)
        }
        if (array.length > 2) {

            let semver = array[2].split(".")
            let major = semver.length > 0 ? parseInt(semver[0]) : 0
            let minor = semver.length > 1 ? parseInt(semver[1]) : 0
            let patch = semver.length > 2 ? parseInt(semver[2]) : 0
            let version = major * 10000 + minor * 100 + patch

            localStorage.setItem(LS_VERSION, version)
            commit('SET_VERSION', version)
        }
        
    },
    setDeeplinkCapability({ commit }, data) {
        
        const intValue = parseInt(data)

        localStorage.setItem(LS_DEEPLINK_CAPABILITY, intValue);
        commit('SET_DEEPLINK_CAPABILITY', intValue);
    },
    setHostOS({ commit }, data) {
        localStorage.setItem(LS_HOST_OS, data)
        commit('SET_HOST_OS', data)
    },
    


}

const mutations = {
    SET_VERIFY_NATIVE: (state, val) => {
        state.isVerifyNative = val;
    },
    SET_FULL_VERSION: (state, val) => {
        state.fullVersion = val;
    },
    SET_PLATFORM: (state, val) => {
        state.platform = val;
    },
    SET_BUNDLEID: (state, val) => {
        state.bundleId = val;
    },
    SET_VERSION: (state, val) => {
        state.intVersion = val;
    },
    SET_DEEPLINK_CAPABILITY: (state, val) => {
        state.deeplinkCap = val;
    },
    SET_HOST_OS: (state, val) => {
        state.hostOS = val;
    }

}
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}