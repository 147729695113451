import httpClient from './httpClient';

const END_POINT_DEVICES = '/devices/';
const END_POINT_PARAMETERS_GET = '/tracker_config/mode_param2/';
const END_POINT_PARAMETERS_PATCH = '/tracker_config/mode_param2/?action=ui';


const getSct2Parameters = (sct2_id) => httpClient.get(END_POINT_DEVICES + sct2_id + END_POINT_PARAMETERS_GET);
const patchSct2Parameters = (sct2_data) => httpClient.patch(END_POINT_DEVICES + sct2_data.sct2_id + END_POINT_PARAMETERS_PATCH, sct2_data.sct2_mode_params);

export {
    getSct2Parameters,
    patchSct2Parameters
}