import httpClient from './httpClient';

const END_POINT = '/users/prices/';
const END_POINT_TRACKER_PRICES = '/users/tracker_prices';

const getAllSubscriptions = () => httpClient.get(END_POINT);
const getAllPricesbyTracker = () => httpClient.get(END_POINT_TRACKER_PRICES)

export {
    getAllSubscriptions,
    getAllPricesbyTracker,
}