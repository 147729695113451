import { getSct2Parameters, patchSct2Parameters } from "../../api/parameters.api"

const state = {
    sct2_params: null,
    isParamsValid: false,
    batteryLife: { min: 0, max: 0 },
}

const getters = {
    async getSct2Params(state) {
        return state.sct2_params;
    },
    getBatteryLife(state) {
        return state.batteryLife;
    },
    getIsParamsValid(state) {
        return state.isParamsValid;
    }
}

const actions = {
    async fetchSct2Params({ commit }, sct2_id) {
        try {
            const response = await getSct2Parameters(sct2_id);
            commit('SET_SCT2_PARAMS', response.data);
            // if ((response.data.frequency >= 1 && response.data.frequency <= 180) && Number.isInteger(response.data.frequency) && (response.data.keepalive_period >= 1 && response.data.keepalive_period <= 1440) && Number.isInteger(response.data.keepalive_period) && response.data.temp_uplink_slices_duration == 2 && (response.data.temp_slice_duration == 5 || response.data.temp_slice_duration == 60 || response.data.temp_slice_duration == 360)) {
            //     commit('SET_IS_PARAMS_VALID', true);
            // } else {
            //     commit('SET_IS_PARAMS_VALID', false);
            // }
        } catch (error) {
            console.log(error);
        }
    },
    async patchSct2Params({ commit }, data) {
        try {
            const response = await patchSct2Parameters(data);
        } catch (error) {
            console.log(error);
        }
    },
    async setSct2Params({ commit }, data) {
        let batterylife = { min: 0, max: 0 };
        commit('SET_SCT2_PARAMS', data);
        if ((data.frequency >= 1 && data.frequency <= 180) && Number.isInteger(data.frequency) && (data.keepalive_period >= 1 && data.keepalive_period <= 1440) && Number.isInteger(data.keepalive_period) && data.temp_uplink_slices_duration == 2 && (data.temp_slice_duration == 60 || data.temp_slice_duration == 720)) {
            commit('SET_IS_PARAMS_VALID', true);
        } else {
            commit('SET_IS_PARAMS_VALID', false);
        }
        if (data.frequency >= 1) {
            batterylife.min = Math.round(12600 / (3 + (8 * 72 / data.frequency)) / 30);
            batterylife.max = Math.round(12600 / (3 + (2 * 72 / data.frequency)) / 30);
            commit('SET_SCT2_BATTERYLIFE', batterylife);
        }
    }
}

const mutations = {
    SET_SCT2_PARAMS(state, data) {
        state.sct2_params = data;
    },
    SET_SCT2_BATTERYLIFE(state, data) {
        state.batteryLife = data;
    },
    SET_IS_PARAMS_VALID(state, data) {
        state.isParamsValid = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}