import { getAllDevices, requestUnlinkTracker, requestActivateSubscription } from "../../api/devices.api"

const state = {
    trackers: [],
    selectedTracker: null,
    mac: null,
    boardName: null,
}

const getters = {
    getDevices(state) {
        return state.trackers;
    },
    getSelectedDevice(state) {
        return state.selectedTracker;
    },
    getMac(state) {
        return state.mac;
    },
    getBoardName(state) {
        return state.boardName
    }
}

const actions = {
    async fetchDevices({ commit }) {
            try {
                const response = await getAllDevices();
                commit('SET_DEVICES', response.data.sort(function(a,b){
                    if ((a.tracker_status.sub_state == "not_activated" && b.tracker_status.sub_state != "not_activated") || (b.tracker_status.sub_state == "not_activated" && a.tracker_status.sub_state != "not_activated")) {
                        return -1;
                    } else if (new Date(a.tracker_status.sub_end_date) > new Date(b.tracker_status.sub_end_date))  
                        return 1;
                    else if(new Date(a.tracker_status.sub_end_date) < new Date(b.tracker_status.sub_end_date))  
                        return -1;  
                    return 0;  
                }));
            } catch (error) {
                console.log(error);
            }    
    },
    async postUnlinkTracker({ commit }, data) {
        try {
            const response = await requestUnlinkTracker(data);
            return response;
        } catch (error) {
            console.log(error)
            console.log(error.response.data)
            return error.response.data
        }
    },
    async postActivateSubscription({ commit }, data) {
        try {
            const response = await requestActivateSubscription(data)
            return response
        } catch (error) {
            return error.response
        }
    },
    async setSelectedDevice({ commit }, data) {
        commit('SET_SELECTEDDEVICE', data);
    },
    async removeDevices({ commit }) {
        commit('REMOVE_DEVICES');
    },
    async removeSelectedDevice({ commit }) {
        commit('REMOVE_SELECTEDDEVICE');
    },
    async setMac({ commit }, data) {
        commit('SET_MAC', data);
    },
    async setBoardName({ commit }, data) {
        commit('SET_BOARDNAME', data);
    },
}

const mutations = {
    SET_DEVICES(state, data) {
        state.trackers = data;
    },
    REMOVE_DEVICES(state) {
        state.trackers = [];
    },
    SET_SELECTEDDEVICE(state, data) {
        data.price = { meta_data: {months: 0}};
        state.selectedTracker = data;
        console.log(state.selectedTracker)
    },
    REMOVE_SELECTEDDEVICE(state) {
        state.selectedTracker = null;
    },
    SET_MAC(state, data) {
        state.mac = data;
    },
    REMOVE_MAC(state) {
        state.mac = null;
    },
    SET_BOARDNAME(state, data) {
        state.boardName = data;
        localStorage.setItem('boardName', data);
    },
    REMOVE_BOARDNAME(state) {
        state.boardName = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}