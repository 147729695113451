import httpClient from './httpClient';

const END_POINT = '/devices/';
const END_POINT_UNLINKTRACKER = '/devices/trackers/unlink_request/';
const END_POINT_ACTIVATE_SUBSCRIPTION = '/activate_subscription/'
const END_POINT_PUBLIC_LINKS = '/public_share_link/';
const END_POINT_DELETE_LINK = '/tracker_public_share_links/';
const END_POINT_THEFT_FORM = '/theft_form/';

const STRIPE_PORTAL_END_POINT = '/users/portal/';

const getAllDevices = () => httpClient.get(END_POINT, {params: {type: 'tracker'}});

const getStripePortal = (return_path) => httpClient.post(STRIPE_PORTAL_END_POINT, {'return_path': return_path});

const requestUnlinkTracker = (tracker_data) => httpClient.post(END_POINT_UNLINKTRACKER, tracker_data);
const requestActivateSubscription = (subscription_data) => httpClient.get(END_POINT + subscription_data.deviceid + END_POINT_ACTIVATE_SUBSCRIPTION, {params: { code: subscription_data.verificationCode }})

const getAllPulicLinks = (device_id) => httpClient.get(END_POINT + device_id + END_POINT_PUBLIC_LINKS) ;
const deletePublicLink = (link) => httpClient.delete(END_POINT_DELETE_LINK + link.uuid + '/');
const addPublicLink = (device_id, paramLink) => httpClient.post(END_POINT + device_id + END_POINT_PUBLIC_LINKS, paramLink)

const getTheftForms = (device_id) => httpClient.get(END_POINT + device_id + END_POINT_THEFT_FORM, {responseType: 'arraybuffer'});

export {
    getAllDevices,
    getStripePortal,
    requestUnlinkTracker,
    requestActivateSubscription,
    getAllPulicLinks,
    deletePublicLink,
    addPublicLink,
    getTheftForms
}