const state = {
    currentIndex: 0,
}
const getters = {
    currentIndex: state => {
        return state.currentIndex;
    }
}

const actions = {
    nextIndex({ commit }, data) {
        commit('NEXT_INDEX', data);
    },
    prevIndex({ commit }) {
        commit('PREV_INDEX');
    }
}

const mutations = {
    NEXT_INDEX: (state, trackersLength) => {
        if (state.currentIndex < trackersLength - 1) 
        state.currentIndex++;
    },
    PREV_INDEX: state => {
        if (state.currentIndex > 0) 
        state.currentIndex--;
    }
}
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}