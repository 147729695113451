import Vue from 'vue';
import Vuex from 'vuex';

import parametersModule from '../store/modules/parameters.module';
import devicesModule from '../store/modules/devices.module'
import subscriptionsModule from './modules/subscriptions.module';
import usersModule from './modules/users.module';
import carouselModule from './modules/carousel.module';
import appcompatModule from './modules/appcompat.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    parameters: parametersModule,
    devices: devicesModule,
    subscriptions: subscriptionsModule,
    users: usersModule,
    carousel: carouselModule,
    appcompat: appcompatModule,
  },
})