import httpClient from './httpClient';

const END_POINT_TOKEN = '/api/v1/auth/web_get_token/';
const END_POINT_LOGIN = '/api-auth/session/login/';
const END_POINT_PAIEMENTSESSION = '/users/payment_session/';
const END_POINT_USERDATA = '/users/me/';
const END_POINT_OPTIONS = '/options/';
const END_POINT_TRACKER_PRICES = '/users/tracker_prices/'
const END_POINT_SETTING = '/users/settings/'

// add this and remove login_data in postUserData to a direct access
// let login_data = { email: 'jean-baptiste.choquet@invoxia.com', password: 'password', };

const deleteUserData = () => httpClient.delete(END_POINT_USERDATA + '?profile=all');

const getUserData = () => httpClient.get(END_POINT_USERDATA);

const getUserSetting = () => httpClient.get(END_POINT_SETTING);

const patchUserSetting = (profil_data) => httpClient.patch(END_POINT_SETTING, profil_data);

// const patchUserData = (user_data) => httpClient.patch(END_POINT_USERDATA, user_data);

const patchUserSendConfirmation = (user_mail) => httpClient.patch(END_POINT_USERDATA, user_mail);

const postUserData = (user_data) => httpClient.post(END_POINT_TOKEN, user_data);

const getUserLogin = () => httpClient.get(END_POINT_LOGIN);

const getUserOptions = () => httpClient.get(END_POINT_OPTIONS);

const postPaiementSession = (paiement_data) => httpClient.post(END_POINT_PAIEMENTSESSION, paiement_data);

const getTrackerPrices = () => httpClient.get(END_POINT_TRACKER_PRICES);

export {
    getUserData,
    getUserSetting,
    patchUserSetting,
    // patchUserData,
    patchUserSendConfirmation,
    deleteUserData,
    postUserData,
    getUserLogin,
    getUserOptions,
    postPaiementSession,
    getTrackerPrices,
}