import {
  getUserData,
  getUserSetting,
  patchUserSetting,
  patchUserSendConfirmation,
  deleteUserData,
  postUserData,
  getUserLogin,
  getUserOptions,
  postPaiementSession,
  getTrackerPrices,
} from "../../api/users.api";

import router from "@/router";

const state = {
  isWebView: false,
  userSetting: null,
  userName: null,
  userRole: null,
  token: null,
};

const getters = {
  getUserSetting(state) {
    return state.userSetting;
  },
  getToken(state) {
    return state.token;
  },
  getUserName(state) {
    return state.userName;
  },
  getUserRole(state) {
    return state.userRole;
  },
  isWebView(state) {
    return state.isWebView;
  },
};

const actions = {
  async fetchUserSetting({ commit }, user_data) {
    try {
      const response = await getUserSetting(user_data);
      commit("SET_USER_SETTING", response.data);
    } catch (error) {
      return error;
    }
  },
  async updateUserProfile({ commit }, data) {
    try {
        const response = await patchUserSetting(data);
        console.log(response)
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
  },
  async sendEmailConfirmation({ commit }, data) {
    try {
      const response = await patchUserSendConfirmation(data);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async fetchUser({ commit }, user_data) {
    try {
      const response = await postUserData(user_data);
      commit("SET_USERNAME", response.data.user);
      commit("SET_TOKEN", response.data.token);
      router.push("/subscription");
    } catch (error) {
      return error;
    }
  },
  async fetchTrackerPrices({ commit }) {
    try {
      const response = await getTrackerPrices();
      return response.data
    } catch (error) {
      return error
    }
  },
  async deleteUserData({ commit }, user_data) {
    try {
      const response = await postUserData(user_data);
      if (response.status == 200) {
        console.log("suppression");
        deleteUserData();
      } else {
        console.log("invalid password");
      }
      return response;
    } catch (error) {
      console.log(error);
      return "error";
    }
  },
  async fetchUserData({ commit }, user_data) {
    try {
      const response = await getUserData(user_data);
      commit("SET_USERNAME", response.data.username);
    } catch (error) {
      return error;
    }
  },
  async login({ commit }) {
    try {
      const response = await getUserLogin();
      commit("SET_USERNAME", response.data.username);
    } catch (error) {
      console.log(error);
    }
  },
  async logout({ commit }) {
    commit("REMOVE_TOKEN");
    if (window.sessionStorage) {
      sessionStorage.clear();
      console.log("session clear");
    }
    router.push("/login");
  },
  async paiementSession({ commit }, paiementSession_data) {
    try {
      const response = await postPaiementSession(paiementSession_data);
      Stripe(response.data.public_key).redirectToCheckout({
        sessionId: response.data.id,
      });
    } catch (error) {
      console.log(error);
    }
  },
  async fetchUserRole({ commit }) {
    try {
      const response = await getUserOptions();
      commit("SET_USERROLE", response.data.role);
    } catch (error) {
      console.log(error);
    }
  },
  async setToken({ commit }, data) {
    commit("SET_TOKEN", data);
  },
  async setIsWebView({ commit }, data) {
    commit("SET_ISWEBVIEW", data);
  },
};

const mutations = {
  SET_USER_SETTING(state, data) {
    state.userSetting = data;
  },
  SET_USER_DATA(state, data) {
    console.log('Mutation SET_USER_DATA called', data);
    state.userSetting.firstname = data.firstname;
    state.userSetting.lastname = data.lastname;
    state.userSetting.birthday = data.birthday;
  },
  SET_ISWEBVIEW(state, data) {
    state.isWebView = data;
  },
  SET_TOKEN(state, data) {
    localStorage.setItem("token", data);
    state.token = data;
  },
  REMOVE_TOKEN(state, data) {
    localStorage.removeItem("token");
    state.token = null;
  },
  SET_USERNAME(state, data) {
    state.userName = data;
  },
  REMOVE_USERNAME(state, data) {
    state.userName = null;
  },
  SET_USERROLE(state, data) {
    state.userRole = data;
  },
  REMOVE_USERROLE(state, data) {
    state.userRole = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
