import Vue from 'vue'

import * as moment from 'moment';

Vue.filter('capitalize', val => val.charAt(0).toUpperCase() + val.slice(1));

Vue.filter('lowercase', val => val.toLowerCase());

Vue.filter('uppercase', val => val.toUpperCase());

Vue.filter('length', val => val.length);

Vue.filter('formatDate', value => {
    if (value) {
        return moment(String(value)).format('LLLL')
    }
});

Vue.filter('formatDay', value => {
    if (value) {
        return moment(String(value)).format('LL')
    }
});

Vue.filter('formatShortDay', value => {
    if (value) {
        return moment(String(value)).format('ll')
    }
});

Vue.filter('formatShortDayHour', value => {
    if (value) {
        return moment(String(value)).format('lll')
    }
})

Vue.filter('formatHour', value => {
    if (value) {
        return moment(String(value)).format('LT')
    }
});

Vue.filter('formatTime', value => {
    if (value) {
        return moment(String(value)).fromNow()
    }
});

Vue.filter('formatDateDDMMYYYY', value => {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
})

Vue.filter('formatDateInterval', value => {

    if (value, value.length > 1) {

        if (!window.Intl) {
            // a from now if not supported.
            return Vue.filter('formatTime')(value[1])
        }

        const date1 = new Date(value[0]);
        const date2 = new Date(value[1]);
        const year1 = date1.getFullYear();
        const year2 = date2.getFullYear();

        const locale = navigator.language
        
        if (year1 == year2) {
            const month1 = date1.getMonth()
            const month2 = date2.getMonth()
            if (month1 == month2) {
                const options1 = { day: 'numeric'};
                const options2 = { day: 'numeric', month: 'short'};
                const day1 = date1.toLocaleDateString(locale, options1);
                const day2 = date2.toLocaleDateString(locale, options2);

                return app.$t('date_from_to_fmt', {date1: day1, date2: day2})
            }
            else {
                const options = { day: 'numeric', month: 'short'};
                const day1 = date1.toLocaleDateString(locale, options);
                const day2 = date2.toLocaleDateString(locale, options);

                return app.$t('date_from_to_fmt', {date1: day1, date2: day2})
            }
        }
        else {
            const options = { day: 'numeric', month: 'short', year: 'numeric'};
                const day1 = date1.toLocaleDateString(locale, options);
                const day2 = date2.toLocaleDateString(locale, options);

                return app.$t('date_from_to_fmt', {date1: day1, date2: day2})
        }
    }
    return "";
})

Vue.filter('validityInterval', value => {

    if (value, value.length > 1) {

        if (!window.Intl) {
            // a from now if not supported.
            return Vue.filter('formatTime')(value[1]);
        }

        const date2 = new Date(value[1]);
        const year2 = date2.getFullYear();
        if (year2 > 2100) {
            return app.$t('shared_link_permanent')
        }

        return Vue.filter('formatDateInterval')(value);
    }
    return "";
})

function formatMode(value) {
    const modes = {
        "1": app.$t('standard_monitoring_frequencyy'),
        "3": app.$t('high_monitoring_frequency'),
        "2": app.$t('intense_monitoring_frequency'),
        "4": app.$t('plane'),
        "7": app.$t('lost_mode'),
        "8": app.$t('standard_monitoring_frequencyy') + ' + ' + app.$t('alert_vehicle'),
        "10": app.$t('high_monitoring_frequency') + ' + ' + app.$t('alert_vehicle'),
        "9": app.$t('intense_monitoring_frequency') + ' + ' + app.$t('alert_vehicle'),
        "21": app.$t('standard_monitoring_frequencyy') + ' + Animal ',
        "23": app.$t('high_monitoring_frequency') + ' + Animal ',
        "22": app.$t('intense_monitoring_frequency') + ' + Animal ',
        "27": app.$t('lost_mode'),
        "50": app.$t('sct2_configutable_mode'),
    }

    if (modes.hasOwnProperty(value)) {
        return modes[value];
    }

    var output = `Mode ${value}`;
    return output;
}

Vue.filter('niceMode', function (value) {
    return formatMode(value);
});


Vue.filter('format_device_type', function (device) {

    if (device.type == "tracker_01") {
        if (device.tracker_config.board_name) {
            if (device.tracker_config.board_name.startsWith("LWT2")) {
                return "Tracker LWT2";
            }
            if (device.tracker_config.board_name.startsWith("SCT2")) {
                return "Tracker SCT2";
            }
        }
        return "Tracker LWT";
    }
    if (device.type == 'tracker_02') {
        return "Tracker SCT";
    }
    if (device.type == 'tracker_03') {
        return "Tracker LWT3";
    }
    if (device.type == "triby") {
        return "Triby";
    }
    if (device.type == 'tracker_04') {
        return "Smart Dog Collar"
    }
    return device.type;
});

Vue.filter('image_device_type', function (device) {
    if (device.type == "tracker_01") {
        if (device.tracker_config.board_name) {
            if (device.tracker_config.board_name.startsWith("LWT2")) {
                if (device.tracker_config.usage == "bike") {
                    return "/images/trackers/bike-tracker.png";
                }
                else {
                    return "/images/trackers/mini-tracker.png";
                }
            } else if (device.tracker_config.board_name.startsWith("SCT2")) {
                return "/images/trackers/sct2-tracker.png";
            }
        }
        return "/images/trackers/lwt-tracker.png";
    }
    if (device.type == 'tracker_03') {
        return "/images/trackers/lwt3-tracker.png";
    } 
    if (device.type == 'tracker_04') {
        return "/images/trackers/smart-dog-collar.png";
    }
    if (device.type == 'tracker_06'){
        return "/images/trackers/lwt6-tracker.png";
    }
});

Vue.filter('image_board_name', function (board_name) {
    if (board_name.includes('LWT1')) {
        return "/images/trackers/lwt-tracker.png";
    } else if (board_name.includes('LWT2')) {
        return "/images/trackers/mini-tracker.png";
    } else if (board_name.includes('SXT2')) {
        return "/images/trackers/sct2-tracker.png";
    } else if (board_name.includes('LWT3')) {
        return "/images/trackers/lwt3-tracker.png";
    } else if (board_name.includes('LWT4')) {
        return "/images/trackers/lwt-tracker.png";
    } else {
        return "/images/trackers/lwt-tracker.png";
    }
});

Vue.filter('formatUsage', function (value) {
    switch (value) {
        case 'person':
            return app.$t('person');
        case 'vehicle':
            return app.$t('vehicle');
        case 'dog':
            return app.$t('dog');
        case 'cat':
            return app.$t('cat');
        case 'other':
            return app.$t('other');
        case 'bike':
            return app.$t('bike');
        case undefined:
            return app.$t('undefined');
        default:
            return `Usage ${value}`;
    }
});

Vue.filter('formatStationary', function (value) {
    if (value < 0) {
        return ''
    }
    if (value < 8) {
        return app.$t('moving')
    }
    if (value > 100) {
        var _hours = Math.round(value / 60);
        return app.$t('stationary_for') + ` ${_hours} ` + app.$t('hours');
    }
    return app.$t('stationary_for') + ` ${value} minutes`;
});

Vue.filter('formatBattery', function (device) {
    if (device.tracker_status) {
        if (device.tracker_status.battery < 0) {
            return "?"
        }

        if (device.type == 'tracker_02') {
            return device.tracker_status.battery + "mV";
        }
        return device.tracker_status.battery + "%";
    }
    return "?"
});

Vue.filter('formatAmount', function(price){
    let locale = navigator.language;
    let options = { style: 'currency', currency: price.currency };
    let numberFormat = new Intl.NumberFormat(locale, options);
    return numberFormat.format(price.unit_amount /100)
});

Vue.filter('formatSubscriptionDuration', function (duration) {
    if (duration >= 12) { 
        return duration / 12  + (duration / 12 >= 2 ? " " + app.$t('years') : " " + app.$t('year'))
    } else {
        return app.$tc('monthKey', duration)
    }
});

Vue.filter('totalDuration', function(subscription) {
    let months = parseInt(subscription.meta_data.months);
    if (subscription.meta_data.extra){
        months += parseInt(subscription.meta_data.extra);
    }
    console.log("months: ", months)
    return months;
});

Vue.filter('formatSubscriptionTotalPrice', function (price) {
    let locale = navigator.language;
    let options = { style: 'currency', currency: price.currency};
    let numberFormat = new Intl.NumberFormat(locale, options);

    return numberFormat.format(price.unit_amount / 100); 
});

Vue.filter('formatSubscriptionMonthlyPrice', function (price) {
    let locale = navigator.language;
    let options = { style: 'currency', currency: price.currency};
    let numberFormat = new Intl.NumberFormat(locale, options);

    return numberFormat.format(price.unit_amount / 100 / price.meta_data.months);
});

Vue.filter('formatSubscriptionYearlyPrice', function (price) {
    let locale = navigator.language;
    let options = { style: 'currency', currency: price.currency};
    let numberFormat = new Intl.NumberFormat(locale, options);

    return numberFormat.format(price.unit_amount / 100 / price.meta_data.months * 12);
});

Vue.filter('formatPrice', function (price) {
    let locale = navigator.language;
    let options = { style: 'currency', currency: price.currency};
    let numberFormat = new Intl.NumberFormat(locale, options);
    let meta_data = price.meta_data;
    if (meta_data.months == 0){
        return "no change";
    } else {
        return meta_data.months + ' ' + 'months' + ': ' + numberFormat.format(price.unit_amount/100);
    }
});

Vue.filter('fullPublicUrl', (uuid) => {
    let base_url = window.location.origin;
    const _url = base_url + '/pub/trackers?token=' + uuid;
    return _url
})