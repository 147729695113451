import Vue from "vue";
import i18n from './i18n'
import Router from 'vue-router';

Vue.use(Router)

export default new Router({
    mode: 'history',

    routes: [
        {
            path: '/subscription',
            name: 'subscription',
            meta: { title: 'Invoxia - ' + i18n.t('subscription') },
            component: () => import("./pages/SubscriptionPage.vue")
        },
        {
            path: '/subscriptionSelector',
            name: 'subscriptionSelector',
            meta: { title: 'Invoxia - ' + i18n.t('subscriptionSelector') },
            component: () => import("@/components/subscription/SubscriptionSelector.vue")
        },
        {
            path: '/messageSubscriptionPrices',
            name: 'messageSubscriptionPrices',
            meta: { title: 'Invoxia - ' + i18n.t('subscription') },
            component: () => import("@/pages/MessageSubscriptionPrices")
        },
        {
            path: '/link_subscription',
            name: 'linkSubscription',
            meta: { title: 'Invoxia - ' + i18n.t('linkSubscription') },
            component: () => import('./pages/LinkSubscription.vue')
        },
        {
            path: '/link_subscription_sucess',
            name: 'linkSubscriptionSucess',
            meta: { title: 'Invoxia - ' + i18n.t('linkSubscriptionSucess') },
            component: () => import('./pages/LinkSubscriptionSucess')
        },
        {
            path: '/account_email_update',
            name: 'accountEmailUpdate',
            meta: { title: 'Invoxia - ' + i18n.t('accountEmailUpdate') },
            component: () => import("./pages/AccountEmailUpdate.vue")
        },
        {
            path: '/account_email_update_complete',
            name: 'AccountEmailUpdateComplete',
            meta: { title: 'Invoxia - ' + i18n.t('AccountEmailUpdateComplete') },
            component: () => import("./pages/AccountEmailUpdateComplete.vue")
        },
        {
            path: '/account_profil_update',
            name: 'AccountProfilUpdate',
            meta: { title: 'Invoxia - ' + i18n.t('AccountProfilUpdate') },
            component: () => import("./pages/AccountProfilUpdate.vue")
        },
        {
            path: '/account_confirmation',
            name: 'accountConfirmation',
            meta: { title: 'Invoxia - ' + i18n.t('accountConfirmation') },
            component: () => import("./pages/AccountConfirmation.vue")
        },
        { // SLA: not sure if this page is used, for a deeplink It is called from backend banner, but in general needs to be replaced by native part.
            path: "/account_email",
            name: 'accountEmail',
            meta: { title: 'Invoxia - ' + i18n.t('accountEmail') },
            component: () => import("./pages/AccountEmail.vue")
        },
        {
            path: "/account_email_sent",
            name: 'accountEmail',
            meta: { title: 'Invoxia - ' + i18n.t('accountEmail') },
            component: () => import("./pages/AccountEmail.vue")
        },
        {
            path: "/account/",
            name: 'accountSettings',
            meta: { title: 'Invoxia - ' + i18n.t('accountSettings') },
            component: () => import("./pages/AccountSettings.vue")
        },
        {
            path: "/account/:pageVersion",
            name: 'accountSettings',
            meta: { title: 'Invoxia - ' + i18n.t('accountSettings') },
            component: () => import("./pages/AccountSettings.vue")
        },
        {
            path: "/account_delete",
            name: 'accountDelete',
            meta: { title: 'Invoxia - ' + i18n.t('accountDelete') },
            component: () => import("./pages/AccountDelete.vue")
        },
        {
            path: "/operators/helium/",
            name: 'heliumOperator',
            meta: { title: 'Invoxia x Helium'},
            component: () => import("./pages/HeliumOperator.vue")
        },
        {
            path: "/config_sct2",
            name: "configSCT2",
            meta: { title: 'Invoxia config sct2'},
            component: () => import("./pages/NewSCT2Param.vue")
        },
        {
            path: "/start_track_app/",
            name: 'startTrackApp',
            meta: {title: 'Invoxia Tracker App'},
            component: () => import("./pages/StartTrackApp.vue")
        },
        {
            path: "/start_sdc_app/",
            name: 'startSDCApp',
            meta: {title: 'Invoxia SDC App'},
            component: () => import("./pages/StartSDCApp.vue")
        },

        {
            path: "/conflicting",
            name: 'productConflicting',
            meta: {title: 'Product Conflicting'},
            component: () => import("./pages/ProductConflicting.vue")
        },
        {
            path: "/conflicting-unlink",
            name: 'productConflictingUnlink',
            meta: {title: 'Product Conflicting Unlink'},
            component: () => import("./pages/ProductConflictingUnlink.vue")
        },
        {
            path: "/conflicting-security",
            name: 'productConflictingSecurity',
            meta: {title: 'Product Conflicting Security'},
            component: () => import("./pages/ProductConflictingSecurity.vue")
        },
        {
            path: "/conflicting-serial",
            name: 'productConflictingSerial',
            meta: {title: 'Product Conflicting Serial'},
            component: () => import("./pages/ProductConflictingSerial.vue")
        },
        {
            path: "/conflicting-fail",
            name: 'productConflictingFail',
            meta: {title: 'Product Conflicting Fail'},
            component: () => import("./pages/ProductConflictingSNfail.vue")
        },
        {
            path: "/install_restart",
            name: 'productConflicting',
            meta: {title: 'Product Conflicting'},
            component: () => import("./pages/InstallRestart.vue")
        },
        {
            path: "/main",
            name: 'main',
            meta: {},
            component: () => import("./pages/Main.vue")
        },
        {
            path: "/contact_us",
            name: "contact us",
            meta: {},
            component: () => import("./pages/ContactUs.vue")
        },
        {
            path: "/verify_auth",
            name: "verify_auth",
            meta: {},
            component: () => import("./pages/VerifyAuth.vue")
        },
        {
            path: "/install_quit",
            name: "install quit",
            meta: {},
            component: () => import("./pages/InstallQuit.vue")
        },
        {
            path: '/login',
            alias: ['/', '/logout'],
            name: 'login',
            meta: { title: 'Invoxia - ' + i18n.t('login_signin') },
            component: () => import("./pages/LoginPage.vue")
        },
        {
            path: '/app/public_links',
            name: 'publicLinks',
            meta: { title: 'Invoxia - public' },
            component: () => import("./pages/PublicLinks.vue")
        },
        {
            path: '/pub/trackers',
            name: 'publicLinksShared',
            meta: { title: 'Invoxia - public' },
            component: () => import("./pages/PublicLinksShow.vue")
        },
        {
            path: '/app/theft_file',
            name: 'theftFile',
            meta: { title: 'Invoxia - theft' },
            component: () => import("./pages/TheftFile.vue")
        },
        {
            path: '/error',
            name: 'error',
            meta: { title: 'Invoxia - ' + i18n.t('error') },
            component: () => import("./pages/Error.vue")
        },
        {
            path: "/app/native/findmy",
            name: "findMy",
            meta: {},
            component: () => import("./pages/NativeFindMy.vue")
        },
        {
            path: "/app/native/rtb",
            name: "realTimeBoost",
            meta: {},
            component: () => import("./pages/NativeBoost.vue")
        },
        {
            path: '*',
            name: '404',
            meta: { title: 'Invoxia - ' + i18n.t('error_404') },
            component: () => import("./pages/Error404.vue")
        }
    ]
});