<template>
  <div id="app">
    <component :is="layout">
      <router-view :layout.sync="layout"></router-view>
    </component>
  </div>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {
      layout: 'div',
    };
  },
  
  components: {
  },

  mounted: function(){
  }
}

</script>

<style lang="scss">
  * {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    font-size: 2rem;
    padding-left: 40px;
  }

  #app {
    position: relative;
    height: 100%;
    background-color: #F1F8FC;
  }
</style>
